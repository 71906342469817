import "./Style.css"
import FooterLogo from '../../Img/footerlogo.png'
import { FiPhoneCall } from "react-icons/fi";

export default function Footer () {
    return(
        <div className="footerContainer">
            <div className="footerLocal">
                <span className="spanTitle"><b>LOCAL DO EVENTO</b></span>
                <span className="spanText"><b>EXPOMINAS - TEÓFILO OTONI|MG</b></span>
                <span className="spanText"><b>PAMPULHINHA</b></span>
            </div>         
            <img className="footerLogo" src={FooterLogo} alt="Logo"/>          
            <div className="footerLocal" style={{alignItems:'flex-end'}}>
                <span className="spanTitle"><b>INFORMAÇÕES</b></span>
                <div style={{alignItems:'center', display:'flex'}}>
                    <FiPhoneCall size={20}  className="phone"/><span className="spanText"><b>(33) 3522-6677</b></span>               
                </div>
            </div>
        </div>
    )
}