import React from "react";
import Menu from "./Menu";
import { BurgerContainer, StyledBurger } from "./styles";

const Burger = (props) => {
  const {open, setOpen, title, setTitle} = props;
  return (
    <BurgerContainer>
      <Menu open={open} setClose={()=>setOpen(false)} title={title} setTitle={setTitle}/>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div /><div /><div />
      </StyledBurger>
    </BurgerContainer>
  );
};

export default Burger;
