import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Routes, Route, HashRouter } from "react-router-dom";
import axios from 'axios';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import About from './Pages/About/About';
import Gallery from './Pages/Gallery/Gallery';
import Map from './Pages/Map/Map';
import SignUp from './Pages/SignUp/SignUp';
import Shows from './Pages/Shows/Shows';
import Footer from './Components/Footer/Footer';
import Expositor from './Pages/Expositor/Expositor';

axios.defaults.baseURL = "https://exponor-api.herokuapp.com/";
//axios.defaults.baseURL = "http://localhost:3001";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${localStorage.getItem("TOKEN")}`;
  return config;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HashRouter basename="/">
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/shows" element={<Shows/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/map" element={<Map/>} />
        <Route path="/signup" element={<SignUp/>} />
        <Route path="/expositor" element={<Expositor/>} />
      </Routes>
      <Footer/>
      </HashRouter>
  </React.StrictMode>
);


