import "./Style.css"
import Exponor2024 from '../../Img/exponor2024.png'
import AboutImg from '../../Img/about.png'

export default function About() {

    return(
        <div className="containerAbout">
            <div className="divTexts">
                <p className="pAbout"><b className="bAbout">Grandes Marcas confirmadas!</b></p>
                <p style={{color:'transparent'}}>.</p>
                <p className="pAbout"><b className="bAbout">+ de 140 estandes empresariais</b></p>
                <div className='line'/>

                <p className="pAbout"><b className="bAbout">4 dias </b> de evento</p>
                <p className="pAbout"><b className="bAbout">Espaço Moda & Beleza</b> com desfile</p>
                <p className="pAbout"><b className="bAbout">Praça de Alimentação</b> ampla e confortável</p>
                <p className="pAbout"><b className="bAbout">Espaço Atacarejo</b> degustação gratuita</p>
                <p className="pAbout"><b className="bAbout">Shows Regionais</b> todos os dias</p>
                <p className="pAbout"><b className="bAbout">Show Nacional</b> com Artista Renomado</p>
                <p className="pAbout"><b className="bAbout">Espaço Automobilístico</b> renovado</p>

                <img className="exponor2022" src={Exponor2024} alt="Exponor2024"/>
                <p className="pAbout"><b className="bAbout">15 a 18 de Agosto</b></p>
                <p className="pAbout" style={{fontSize:'1.75vw', marginBottom:'5vw'}}>Expominas - Teófilo Otoni/MG</p>

                {/* <p className="pAbout" style={{fontSize:'1.5vw', color:'#009C4A',textIndent:'1.5vw'}}>25 a 27 de Agosto</p>
                <p className="pAbout" style={{fontSize:'2vw', fontWeight:'900'}}>qui. sex. sáb. <span style={{fontSize:'2vw', color:'#009C4A'}}> 17 às 23 horas</span></p>

                <p className="pAbout" style={{fontSize:'1.5vw', color:'#009C4A',textIndent:'4.6vw'}}>28 de Agosto</p>
                <p className="pAbout" style={{fontSize:'2vw', fontWeight:'900',textIndent:'6.6vw', }}>dom. <span style={{fontSize:'2vw', color:'#009C4A'}}> 10 às 17 horas</span></p> */}

            </div>
            <img src={AboutImg} className="AboutImg" alt='AboutImg'/>
        </div>
        
    )
}