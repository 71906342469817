import "./Style.css"
import Timer from "../../Components/Timer/Timer"
import News from "../../Components/News/News"
import Slider from "../../Components/Slider/Slider"
import { Shows } from "../../Components/Slider/Photos"
import React from "react";
import Ballon from '../../Img/button.png'



export default function Home() {
    


    return(
        <div className="containerHome">
            <div className="divHome">
                {/* <a href="https://www.canva.com/design/DAFHc19KNCY/n-q0sJWUaShETc0vwOjv5Q/edit?utm_content=DAFHc19KNCY&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target="_blank"  rel="noreferrer noopener">
                    <img src={Ballon} alt='Botao' className="ballon"/>
                </a> */}
                <Timer/>
            </div>
            <Slider images={Shows} navigation={false} style={{width: "90vw"}} styleMobile={{width: "90vw"}}/>
            <News/>
                
        </div>
        
    )
}