import "./Style.css"
import React from 'react';
import {  HiCursorClick } from 'react-icons/hi'
import { Expositores } from "./Expositores/Photos";

export default function Expositor() {

    return(
        <div className="containerExpositor">
            <span className="titleExpositor">EXPOSITORES</span>
            <div className="divFrames">
                {Expositores.map((expositor, index)=>(
                            <img key={index} src={expositor}  alt="Logo" className="expositorLogo"/>
                            
                ))}
           </div>
           <span className="textExpositor">MANUAL DO EXPOSITOR</span>
            <a href="/REGULAMENTO DO EXPOSITOR.pdf" target="_blank" rel="noreferrer noopener">
                <p className="buttonExpositor" >CLIQUE AQUI <HiCursorClick size={'2vw'} className="cursor"/></p>
            </a>
        </div>

    )
}