import "./Style.css"
// import Paralamas from '../../Img/paralamas.png'
import Image01 from './Img/01.jpg'
export default function News(){
    return(
        <div className="newsContainer">
            <span className="newsTitle">Últimas Notícias</span>
            <div className="newsDiv">
                    <a className="newsCard" target="blank" rel="noopener noreferrer" href="https://www.instagram.com/p/C87veh3OFKt/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
                        <img className="newsImg" src={Image01} alt="Imagem da noticia"/>
                        <div className = "newsRight">
                            <p className="newsText">Sucesso Total marca o coquetel de lançamento do Espaço Moda & Beleza da EXPONOR 2024</p>
                            <p className="newsButton">SAIBA MAIS</p>
                        </div>
                    </a>
                    {/* <a className="newsCard" href="https://exponormg.com.br/">
                        <img className="newsImg" src={Paralamas} alt="Imagem da noticia"/>
                        <div className = "newsRight">
                            <p className="newsText">Atração confirmada na sexta-feira, 26 de agosto Os Paralamas do Sucesso.</p>
                            <p className="newsButton">SAIBA MAIS</p>
                        </div>
                    </a>
                    <a className="newsCard" href="https://www.sicoob.com.br/web/sicoobcredivalemg/sicoob-credivale-mg">
                        <img className="newsImg" src="https://files.nsctotal.com.br/s3fs-public/styles/paragraph_image_style/public/2019-01/4.3-Sicoob-inaugura-ag%C3%AAncia-em-Carapebus-dia-6-de-abril.jpg?qd3.k3y5xVTx2jMa_stOaisVTm.fdrws&itok=10r7i171" alt="Imagem da noticia"/>
                        <div className = "newsRight">
                            <p className="newsText">Sicoob assina o primeiro front stage da Exponor 2022 para os grandes shows. Uma parceria inédita na região!</p>
                            <p className="newsButton">SAIBA MAIS</p>
                        </div>
                        
                    </a>
                    <a className="newsCard" href="https://aceto.com.br/blog/presidente-da-ace-to-participa-da-abertura-da-expoleste-e-convida-empresarios-do-vale-do-rio-doce-para-participarem-da-exponor-2022/">
                        <img className="newsImg" src="https://aceto.com.br/wp-content/uploads/2022/05/ACE_EXGV_01.jpg" alt="Imagem da noticia"/>
                        <div className = "newsRight">
                            <p className="newsText">Presidente da ACE-TO participa da abertura da EXPOLESTE e convida empresários do Vale do Rio Doce para participarem da EXPONOR 2022</p>
                            <p className="newsButton">SAIBA MAIS</p>
                        </div>
                    </a> */}
                  
            </div>
        </div>
    )
}