import React from "react";
import Burger from "./Burguer";
import { Nav } from "./styles";
import { Link } from "react-router-dom";
import Logo from "../../Img/logo.png"
import Realizacao from "../../Img/realizacao.png"

const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");

   
  return (
    <Nav>
      <div className="left"/>
      <Link to="/" onClick={()=>{setOpen(false);setTitle(" ")}}>
        <img className='logo' src={Logo} alt="Logo" />
      </Link>
      <div className='right'>
        <img src={Realizacao} className="realizacao" alt="Realização"/>
        <Burger open={open} setOpen={setOpen} title={title} setTitle={setTitle}/>
        <div className="realizacao"/>
      </div>
    </Nav>
  );
};

export default Navbar;
