import "./Style.css"
import React from "react";

export default function Shows() {

    return(
        <div className="containerShows">
            {/* <div className="divShows">
                <img src={Dia252628} className='dia252628Img' alt='programação dias 25e26e28'/>

                <div className="div27">
                    <img src={Dia27} className='dia27Img' alt='programação dia 27'/>
                    <a className="divClick" href="https://divinoticket.com.br/evento/exponor-2022">
                        <p className="buttonShows" >CLIQUE AQUI</p>
                        <img src={Divino} className='divinoImg' alt="Divino ticket"/>
                    </a>
                </div>
            </div>    */}
        </div>
        
    )
}