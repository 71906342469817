import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Style.css"

export default function SignUp() {
    const [isLoading,setLoading] = React.useState(false);
    const [user,setUser] = React.useState({});
    const navigate = useNavigate();

    const signUp = (event) => {
        setLoading(true)
        event.preventDefault();
        axios.post("/user", user)
            .then((response) => {
                console.log(response.data)
                navigate("/");
                document.location.reload(true);
                setLoading(false);
            }).catch(error => {
                alert(error.response.data.message)
                navigate("/signup");
                document.location.reload(true);
                setLoading(false);
                });
    };

    return(
        <div className="containerSignUp">
            {isLoading? <div className="spinner"/>:
            <form className="formSignUp" onSubmit={signUp}>
                <input className="inputName" type='text' placeholder="Nome"  onChange={e => setUser({...user,name: e.target.value})} required/>
                <input className="inputEmailSignUp" type='email' placeholder="Email"  onChange={e => setUser({...user,email: e.target.value})} required/>
                <input className="inputPasswordSignUp" type='password' placeholder="Senha"  onChange={e => setUser({...user,password: e.target.value})} required/>
                    <button type={"submit"} className="buttonSignUpSignUp">CADASTRO</button>
            </form>}        
        </div>
        
    )
}