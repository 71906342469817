
import Home01 from "../../Img/01.png"
import Home02 from "../../Img/02.png"
import Home03 from "../../Img/03.jpg"
import Home04 from "../../Img/04.jpg"
import Home05 from "../../Img/05.jpg"

import Photo01 from '../../Img/2022/01.jpg'
import Photo02 from '../../Img/2022/02.jpg'
import Photo03 from '../../Img/2022/03.jpg'
import Photo04 from '../../Img/2022/04.jpg'
import Photo05 from '../../Img/2022/05.jpg'
import Photo06 from '../../Img/2022/06.jpg'
import Photo07 from '../../Img/2022/07.jpg'
import Photo08 from '../../Img/2022/08.jpg'
import Photo09 from '../../Img/2022/09.jpg'
import Photo10 from '../../Img/2022/10.jpg'
import Photo11 from '../../Img/2022/11.jpg'
import Photo12 from '../../Img/2022/12.jpg'
import Photo13 from '../../Img/2022/13.jpg'
import Photo14 from '../../Img/2022/14.jpg'
import Photo15 from '../../Img/2022/15.jpg'
import Photo16 from '../../Img/2022/16.jpg'
import Photo17 from '../../Img/2022/17.jpg'
import Photo18 from '../../Img/2022/18.jpg'
import Photo19 from '../../Img/2022/19.jpg'
import Photo20 from '../../Img/2022/20.jpg'
import Photo21 from '../../Img/2022/21.jpg'
import Photo22 from '../../Img/2022/22.jpg'
import Photo23 from '../../Img/2022/23.jpg'
import Photo24 from '../../Img/2022/24.jpg'
import Photo25 from '../../Img/2022/25.jpg'
import Photo26 from '../../Img/2022/26.jpg'
import Photo27 from '../../Img/2022/27.jpg'
import Photo28 from '../../Img/2022/28.jpg'
import Photo29 from '../../Img/2022/29.jpg'
import Photo30 from '../../Img/2022/30.jpg'
import Photo31 from '../../Img/2022/31.jpg'
import Photo32 from '../../Img/2022/32.jpg'
import Photo33 from '../../Img/2022/33.jpg'
import Photo34 from '../../Img/2022/34.jpg'
import Photo35 from '../../Img/2022/35.jpg'
import Photo36 from '../../Img/2022/36.jpg'
import Photo37 from '../../Img/2022/37.jpg'
import Photo38 from '../../Img/2022/38.jpg'
import Photo39 from '../../Img/2022/39.jpg'
import Photo40 from '../../Img/2022/40.jpg'
import Photo41 from '../../Img/2022/41.jpg'
import Photo42 from '../../Img/2022/42.jpg'
import Photo43 from '../../Img/2022/43.jpg'
import Photo44 from '../../Img/2022/44.jpg'
import Photo45 from '../../Img/2022/45.jpg'
import Photo46 from '../../Img/2022/46.jpg'
import Photo47 from '../../Img/2022/47.jpg'
import Photo48 from '../../Img/2022/48.jpg'
import Photo49 from '../../Img/2022/49.jpg'
import Photo50 from '../../Img/2022/50.jpg'
import Photo51 from '../../Img/2022/51.jpg'
import Photo52 from '../../Img/2022/52.jpg'
import Photo53 from '../../Img/2022/53.jpg'
import Photo54 from '../../Img/2022/54.jpg'
import Photo55 from '../../Img/2022/55.jpg'
import Photo56 from '../../Img/2022/56.jpg'
import Photo57 from '../../Img/2022/57.jpg'
import Photo58 from '../../Img/2022/58.jpg'
import Photo59 from '../../Img/2022/59.jpg'
import Photo60 from '../../Img/2022/60.jpg'
import Photo61 from '../../Img/2022/61.jpg'
import Photo62 from '../../Img/2022/62.jpg'
import Photo63 from '../../Img/2022/63.jpg'
import Photo64 from '../../Img/2022/64.jpg'
import Photo65 from '../../Img/2022/65.jpg'
import Photo66 from '../../Img/2022/66.jpg'
import Photo67 from '../../Img/2022/67.jpg'
import Photo68 from '../../Img/2022/68.jpg'

export const Shows = [Home01,Home02,Home03,Home04,Home05]

export const Exponor2022 = [Photo01,Photo02,Photo03,Photo04,Photo05,Photo06,Photo07,Photo08,Photo09,Photo10,Photo11,Photo12,Photo13,Photo14,Photo15,Photo16,Photo17,Photo18,Photo19,Photo20,Photo21,Photo22,Photo23,Photo24,Photo25,Photo26,Photo27,Photo28,Photo29,Photo30,Photo31,Photo32,Photo33,Photo34,Photo35,Photo36,Photo37,Photo38,Photo39,Photo40,Photo41,Photo42,Photo43,Photo44,Photo45,Photo46,Photo47,Photo48,Photo49,Photo50,Photo51,Photo52,Photo53,Photo54,Photo55,Photo56,Photo57,Photo58,Photo59,Photo60,Photo61,Photo62,Photo63,Photo64,Photo65,Photo66,Photo67,Photo68]







