import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay} from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import "./Style.css"


// props = navigation, [images] , index
export default function Slider (props) {
    const width = document.body.clientWidth;
    const settings = {
        spaceBetween: 0,
        slidesPerView: 1,
        navigation: props.navigation,
        pagination:{clickable:true},
        loop: true,
        autoplay:{delay:5000}
    }

    return(
        <div className='containerSlider'>
            <Swiper
            initialSlide={props.index}
            {... settings}
            modules={[Navigation, Pagination, Autoplay]}
            >
                {props.images?.map((image,index)=>(
                    <SwiperSlide key={index}>
                        <img className="slideImg" src={image} alt='Imagem do slide' style={width<1055? props.styleMobile : props.style}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>

    )
}