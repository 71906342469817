import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding-top: 1vw;
  .left{
    background-color: black;
    height: 3.6vw;
    width: 4vw;
  }
  .logo{
    width:16.5vw;
    border-radius: 1vw;
  }
  .right{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-end;
  }
  .realizacao{
    align-self: flex-end;
    width: 30vw;
    height: 3.6vw;
  }

  @media (max-width: 1055px) {
    width: 100vw;
    .left{
      height: 10vw;
    }
   .logo{
    width: 30vw;
    }
    .realizacao{
      width: 55vw;
      height: 7vw;
    }   
  
   }
`;

export const BurgerContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: black;
  width: 100%;

  @media (max-width: 1055px) {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 10vw;

  }
`;
export const StyledBurger = styled.div`
  cursor: pointer;
  display: none;
  height: 6vw;
  width: 8vw;

  @media (max-width: 1055px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column nowrap;
    margin-right: 5vw;
    z-index: 1;
  }

  div {
    width: 100%;
    height: 0.5vw;
    background-color: ${({ open }) => (open ? "#E7B928" : "#E7B928")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    position: relative;
    

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
      
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      
    }
  }
`;

export const MenuDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: black;
  height: 3.6vw;
  width: 100%;

  span {
    display: flex;
    margin: .5rem 1rem;
    text-decoration: none;
    color: ${({ open }) => (open ? "#fff" : "#fff")};
    font-size: 1.7vw;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      transition: all 500ms ease;
      transform: scale(1.1);
    }
  }
  a:hover {
      cursor: pointer;
      transition: all 500ms ease;
      transform: scale(1.1);
    }
  @media (max-width: 1055px) {
    flex-flow: column nowrap;
    background-color: black;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100%;
    max-width: 50%;
    padding-top: 25vw;
    transition: transform 0.5s ease-in-out;
    margin-top: 0;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
    
    span {
      font-size: 4vw;
    }
    .instagram{
      align-self: center;
    }
    
  }
`;