import React from "react";
import { Link } from "react-router-dom";
import { MenuDiv } from "./styles";
import { AiOutlineInstagram, AiOutlineFacebook } from 'react-icons/ai'


const Menu = ({ open, setClose, title, setTitle }) => {

  return (
    <MenuDiv open={open}>
        <Link to="/about" onClick={()=>{setClose();setTitle('/about')}}><span style={{color:title==='/about'?'#E7B928':'white'}}>O Evento</span></Link>
        <Link to="/shows" onClick={()=>{setClose();setTitle('/shows')}}><span style={{color:title==='/shows'?'#E7B928':'white'}}>Shows</span></Link>
        <Link to="/gallery" onClick={()=>{setClose();setTitle('/gallery')}}><span style={{color:title==='/gallery'?'#E7B928':'white'}}>Fotos</span></Link>
        <Link to="/map" onClick={()=>{setClose();setTitle('/map')}}><span style={{color:title==='/map'?'#E7B928':'white'}}>Mapas</span></Link>
        <Link to="/expositor" onClick={()=>{setClose();setTitle('/expositor')}}><span style={{color:title==='/expositor'?'#E7B928':'white'}}>Expositores</span></Link>
        {/* <a href="https://app.exponormg.com.br/pre-cadastro/novo" onClick={()=>{setClose();setTitle('/signup')}}><span style={{color:title==='/signup'?'#E7B928':'white'}}>Pré-Cadastro</span></a> */}
        <div className="social">
        <a href='https://www.instagram.com/exponor.teo/?hl=pt' className="instagram"><AiOutlineInstagram color='#E7B928' size={50}/></a>
        <a href='https://www.facebook.com/aceteofilootonimg/' className="instagram"><AiOutlineFacebook color='#E7B928' size={50} style={{borderRadius:25}}/></a>
        </div>

    </MenuDiv>
  )   
};

export default Menu;
