import "./Style.css"
import {  HiCursorClick } from 'react-icons/hi'
import Mapa from './mapa.png'

export default function Map() {

    return(
        <div className="containerMap">
            <img src={Mapa} className="mapImg" alt='Mapa do evento'/>

            <span className="spanMap"> CONFIRA NOSSOS EXPOSITORES JÁ CONFIRMADOS</span>
            <a href="https://app.exponormg.com.br/mapas/site/2024">
                        <p className="buttonMap" >CLIQUE AQUI <HiCursorClick size={'2.7vw'} className="cursor"/></p>
            </a>
        </div>
        
    )
}