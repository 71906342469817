import React from 'react';
import './Style.css'
import { MdOutlineAccessTime } from 'react-icons/md'


export default function Timer(){
    const finalDate = new Date("15 Aug 2024");
    const currentDate = new Date();
    const [totalTimeInSeconds, setTotalTimeInSeconds] = React.useState((finalDate-currentDate)/1000);

    const seconds = Math.floor(totalTimeInSeconds) % 60;
    const minutes = Math.floor(totalTimeInSeconds/60)%60;
    const hours = Math.floor(totalTimeInSeconds/3600)%24;
    const days =  Math.floor(totalTimeInSeconds/3600/24);

    React.useEffect(()=>{
        if(totalTimeInSeconds>0){
            setTimeout(()=>{
                setTotalTimeInSeconds(totalTimeInSeconds - 1);
            }, 1000)
        }
    }, [totalTimeInSeconds])

    return (
        <div className="countdown">
            <div className="countdownText">
                <MdOutlineAccessTime size={80} style={{color:'white'}}/>
                <span>Falta pouco para a #Exponor2024</span>
                <div/>
            </div>
            <div className='timer'>
                <div className='timerBox'>
                    <span className='timerNumber'> {days<0? 0 :days.toString().padStart(2,"0")}</span>
                    <span className='timerName'>Dias</span>
                </div>
                <span className='timerNumber'>:</span>
                <div className='timerBox'>
                    <span className='timerNumber'> {hours<0? 0 :hours.toString().padStart(2,"0")}</span>
                    <span className='timerName'>Horas</span>
                </div>
                <span className='timerNumber'> :</span>
                <div className='timerBox'>
                    <span className='timerNumber'> {minutes<0? 0 : minutes.toString().padStart(2,"0")}</span>
                    <span className='timerName'>Minutos</span>
                </div>
                <span className='timerNumber'>:</span>
                <div className='timerBox'>
                    <span className='timerNumber'> {seconds<0? 0: seconds.toString().padStart(2,"0")}</span>
                    <span className='timerName'>Segundos</span>
                </div>
            </div>
        </div>
    )

}
