import "./Style.css"
import Slider from "../../Components/Slider/Slider"
import Modal from 'react-modal';
import React from "react";
import { Exponor2022 } from "../../Components/Slider/Photos";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 500
      
    },
  };

export default function Gallery() {
    const [open, setOpen] = React.useState(false);
    const [index, setIndex] = React.useState();
   
    return(
        <div className="containerGallery">
            <div className="galleryDiv">
                <p className="title">Exponor 2022</p>
                <div className='photos'>
                {Exponor2022?.map((image,index)=>(
                    <img key={index} className="galleryImg" src={image} alt='Galeria exponor' onClick={()=>{setIndex(index);setOpen(true)}}/>
                ))}
                </div>
            </div>         
            <Modal isOpen={open} shouldCloseOnEsc={true} onRequestClose={()=>setOpen(false)} style={customStyles} >
            <button className="buttonClose" onClick={()=> setOpen(false)}>✘</button>
            <Slider images={Exponor2022} index={index} navigation={true} style={{height:"80vh",width: "auto"}} styleMobile={{height:"auto",width: "90vw"}}/>
            </Modal>
            </div>
        
    )
}